import {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUp} from "@fortawesome/free-solid-svg-icons";

function BackToTop() {
    const [hasScrolled, setHasScrolled] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", onScroll);
        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    });

    const onScroll = () => {
        if (window.scrollY > 100 && !hasScrolled) {
            setHasScrolled(true);
        } else if (window.scrollY < 100 && hasScrolled) {
            setHasScrolled(false);
        }
    };

    return (
        <>
            {hasScrolled && (
                <a id="scrollUp" className='d-flex align-items-center justify-content-center' href="#top"
                   style={{position: 'fixed', zIndex: 2147483647}}>
                    <FontAwesomeIcon icon={faArrowUp} className='font-1-3em'/>
                </a>
            )}
        </>
    );
}

export default BackToTop;
