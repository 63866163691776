import Link from "next/link";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {ButtonProps} from "./BlackButton";
import React from "react";

const WhiteButton: React.FC<ButtonProps> = ({href, text, hoverUp = true, className, faIcon}) => {
    return (
        <>
            <div className={hoverUp ? "hover-up" : ''}>
                <Link href={href} legacyBehavior>
                    <a className={`btn btn-default ${className}`}>
                        {faIcon && <><FontAwesomeIcon icon={faIcon}/> {" "}</>}
                        {text} <FontAwesomeIcon className="font-xs" icon={faChevronRight}/>
                    </a>
                </Link>
            </div>
        </>
    );
};

export default WhiteButton;
