import Link from "next/link";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import MenuItems from "./MenuItems";
import WhiteButton from "../shared/WhiteButton";
import Image from "next/image";
import React from "react";

interface HeaderProps {
    toggleMenu: () => void;
}

const Header: React.FC<HeaderProps> = ({toggleMenu}) => {
    return (
        <>
            <header className="header sticky-bar bg-light-green">
                <div className="container">
                    <div className="main-header">
                        <div className="header-left">
                            <div className="header-logo d-none d-xl-block">
                                <Link href="/" legacyBehavior>
                                    <a className="d-flex">
                                        <Image priority={true} alt="Logo of Cohesian" width='250' height='130'
                                               src="/assets/imgs/logo.svg"/>
                                    </a>
                                </Link>
                            </div>
                            <div className="header-logo mobile-logo">
                                <Link href="/" legacyBehavior>
                                    <a className="d-flex">
                                        <Image priority={true} alt="Logo of Cohesian" width='134' height='90'
                                               src="/assets/imgs/logo.svg"/>
                                    </a>
                                </Link>
                            </div>
                            <div className="header-nav d-none d-xl-block">
                                <nav className="nav-main-menu">
                                    <MenuItems className="main-menu"/>
                                </nav>
                            </div>
                        </div>
                        <div className="burger-icon" onClick={toggleMenu}>
                            <FontAwesomeIcon icon={faBars} size="2x"/>
                        </div>
                        <div className="header-right d-none d-xl-block">
                            <div className="block-signin">
                                <WhiteButton href='/Spontaan' text="Join cohesian"/>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

export default Header;
