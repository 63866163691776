import React from 'react';

interface SectionProps {
    children: React.ReactNode;
    className?: string;
    id?: string;
}

const Section: React.FC<SectionProps> = ({children, className = '', id}) => {
    return (
        <section id={id} className={`section-box mt-lg-100 mt-40 ${className}`}>
            {children}
        </section>
    );
};

export default Section;
