import SocialMedia from "../shared/SocialMedia";
import React from "react";
import Image from "next/image";
import {handleLog} from "../logger.helper";
import {useRouter} from "next/router";
import Link from "next/link";

const Footer: React.FC = () => {
    const router = useRouter();

    const handleLinkClick = async (path: string) => {
        await router.push(path);
    };

    return (
        <>
            <footer className="footer">
                <div className="container">
                    <div className="row mt-80">
                        <div className="col-sm-12 col-md-4 col-lg-4 mb-30 text-lg-start text-md-start text-center">
                            <a
                                className="pointer d-flex justify-content-center justify-content-lg-start justify-content-md-start"
                                onClick={() => handleLinkClick("/")}
                            >
                                <Image
                                    priority={true}
                                    alt="Logo of Cohesian"
                                    width="150"
                                    height="100"
                                    src="/assets/imgs/logo.svg"
                                />
                            </a>
                            <div className="mt-10 text-body-text color-gray-600">
                <span className="font-bold color-green-900">
                  Connection
                </span>{" "}
                                creates excellence
                            </div>
                            <div className="mt-5">
                                <SocialMedia
                                    className="justify-content-lg-start justify-content-md-start justify-content-center"
                                />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4 mb-30 text-center">
                            <h4 className="text-heading-5">Contact</h4>
                            <div className="mt-10 text-body-text color-gray-600">
                                Deken Habrakenstraat 7
                                <br/>
                                3511 Hasselt
                            </div>
                            <div className="mt-15 text-body-text color-gray-600">
                                <a href="tel:+32484542179" className="color-green-900">
                                    <strong>(+32) 484542179</strong>
                                </a>
                                <br/>
                                <a
                                    href="mailto:hello@cohesian.be"
                                    className="color-green-900"
                                >
                                    <strong>hello@cohesian.be</strong>
                                </a>
                                <br/>
                                <p className="color-green-900">
                                    <strong>BTW:</strong> BE 0788.411.248
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4 mb-30 text-lg-end text-md-end text-center">
                            <h4 className="text-heading-5">Handige links</h4>
                            <ul className="menu-footer mt-10">
                                <li>
                                    <a
                                        className="pointer text-body-text color-gray-900"
                                        onClick={() => handleLinkClick("/")}
                                    >
                                        Home
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="pointer text-body-text color-gray-900"
                                        onClick={() => handleLinkClick("/Diensten")}
                                    >
                                        Diensten
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="pointer text-body-text color-gray-900"
                                        onClick={() => handleLinkClick("/Vacatures")}
                                    >
                                        Vacatures
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="pointer text-body-text color-gray-900"
                                        onClick={() => handleLinkClick("/OverOns")}
                                    >
                                        Over ons
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="pointer text-body-text color-gray-900"
                                        onClick={() => handleLinkClick("/Contact")}
                                    >
                                        Contact
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="pointer text-body-text color-gray-900"
                                        onClick={() => handleLinkClick("/Spontaan")}
                                    >
                                        Spontaan solliciteren
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row flex-sm-row-reverse">
                        <div className="col-lg-6 col-md-12 col-sm-12 text-lg-end text-center">
                            <Link href="#" legacyBehavior>
                                <a
                                    className="text-body-text color-gray-600 mx-1"
                                >
                                    Privacy beleid
                                </a>
                            </Link>
                            <Link href="#" legacyBehavior>
                                <a
                                    className="text-body-text color-gray-600 mx-1"
                                >
                                    Algemene voorwaarden
                                </a>
                            </Link>
                        </div>

                        <div className="col-lg-6 col-md-12 col-sm-12 text-lg-start text-center">
                            <div className="text-body-text color-gray-600">
                                ©Cohesian 2023 Powered by{" "}
                                <Link
                                    href="https://anivia.be/"
                                    legacyBehavior
                                >
                                    <a
                                        target="_blank"
                                        rel="noopener"
                                        onClick={() =>
                                            handleLog("Clickrate_ANIVIA", "All", "Clickrate_ANIVIA")
                                        }
                                        className="font-bold color-green-900"
                                    >
                                        ANIVIA
                                    </a>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;
