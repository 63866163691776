import React from "react";
import Image from "next/image";
import Link from "next/link";

export interface SocialMediaProps {
    className?: string;
}


const SocialMedia: React.FC<SocialMediaProps> = ({className = ""}) => {
    return (
        <>
            <div className={`d-flex ${className}`}>
                <Link href="https://www.linkedin.com/company/cohesianengineering/" legacyBehavior>
                    <a target="_blank" rel="noopener">
                        <div className="hover-up">
                            <Image className='mr-5 ' width={22} height={22}
                                   src="/assets/imgs/icons/linkedin.svg" alt="Logo van Linkedin"/>
                        </div>
                    </a>
                </Link>
                <Link href="https://www.facebook.com/cohesianengineering" legacyBehavior>
                    <a target="_blank" rel="noopener">
                        <div className="hover-up">
                            <Image className='mr-5' width={22} height={22} src="/assets/imgs/icons/square-facebook.svg"
                                   alt="Logo van Facebook"/>
                        </div>
                    </a>
                </Link>
                <Link href="https://www.instagram.com/cohesianengineering/" legacyBehavior>
                    <a target="_blank" rel="noopener">
                        <div className="hover-up">
                            <Image width={22} height={22} src="/assets/imgs/icons/square-instagram.svg"
                                   alt="Logo van Instagram"/>
                        </div>
                    </a>
                </Link>

            </div>
        </>
    );
};

export default SocialMedia;
