import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import MenuItems from "./MenuItems";

interface SidebarProps {
    toggleMenu: () => void;
    menuToggled: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({toggleMenu, menuToggled}) => {
    return (
        <>
            <div className={`mobile-header-active mobile-header-wrapper-style ${menuToggled ? 'sidebar-visible' : ''}`}>
                <div className="mobile-header-wrapper-inner">
                    <div className='header'>
                        <div className='container'>
                            <div className='d-flex justify-content-end' onClick={toggleMenu}>
                                <FontAwesomeIcon icon={faTimes} size="2x"/>
                            </div>
                        </div>
                    </div>
                    <div className="mobile-header-content-area">
                        <div className="mobile-menu-wrap mobile-header-border">
                            <nav>
                                <MenuItems onClick={toggleMenu} className='mobile-menu font-heading'/>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Sidebar;
