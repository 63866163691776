/* eslint-disable @next/next/no-img-element */
import Link from "next/link";
import {useRouter} from "next/router";

interface MenuItemsProps {
    className: string;
    onClick?: () => void;
}

const MenuItems: React.FC<MenuItemsProps> = ({className, onClick}) => {
    const router = useRouter();

    return (
        <>
            <ul className={className}>
                <li onClick={onClick}>
                    <Link href="/" legacyBehavior>
                        <a className={router.pathname === "/" ? "active" : ""}>Home</a>
                    </Link>
                </li>
                <li onClick={onClick}>
                    <Link href="/Diensten" legacyBehavior>
                        <a className={router.pathname === "/diensten" ? "active" : ""}>Diensten</a>
                    </Link>
                </li>
                <li onClick={onClick}>
                    <Link href="/Vacatures" legacyBehavior>
                        <a className={router.pathname === "/vacatures" ? "active" : ""}>Vacatures</a>
                    </Link>
                </li>
                <li onClick={onClick}>
                    <Link href="/OverOns" legacyBehavior>
                        <a className={router.pathname === "/over-ons" ? "active" : ""}>Over ons</a>
                    </Link>
                </li>
                <li onClick={onClick}>
                    <Link href="/Contact" legacyBehavior>
                        <a className={router.pathname === "/contact" ? "active" : ""}>Contact</a>
                    </Link>
                </li>
            </ul>
        </>
    );
};

export default MenuItems;
