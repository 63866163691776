import React, {useState} from 'react';
import BackToTop from "../elements/BackToTop";
import Footer from "./Footer";
import Header from "./Header";
import Sidebar from "./Sidebar";
import StickyHeader from "./StickyHeader";

interface LayoutProps {
    children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({children}) => {
    const [menuToggled, setMenuToggled] = useState(false);

    const updateOverlay = () => {
        menuToggled ? document.body.classList.remove("mobile-menu-active") : document.body.classList.add("mobile-menu-active");
    }

    const toggleMenu = () => {
        updateOverlay();
        setMenuToggled(!menuToggled);
    }

    return (
        <>
            {menuToggled && <div className="body-overlay-1" onClick={toggleMenu}/>}
            <StickyHeader toggleMenu={toggleMenu}/>
            <Header toggleMenu={toggleMenu}/>
            <Sidebar toggleMenu={toggleMenu} menuToggled={menuToggled}/>
            <main className="main">
                {children}
            </main>
            <Footer/>
            <BackToTop/>
        </>
    );
};

export default Layout;
